<template>
  <v-footer height="70"> 
<v-row justify="space-between" align="center">
  <v-col md="3" class="text--#155666">
    <v-card color="transparent" flat>
      <v-card-actions>
        <v-btn id="homepage" :ripple="false" color="#155666" text tile x-small href="https://www.slenderiser.de">Homepage</v-btn>
        <v-btn :ripple="false" color="#155666" text tile x-small href="https://www.slenderiser.de/impressum/">Impressum</v-btn>
        <v-btn :ripple="false" color="#155666" text tile x-small href="https://www.slenderiser.de/datenschutzerklaerung/">Datenschutz</v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
  <v-col md="3">
     <v-card class="text-end" color="transparent" flat>
      
    </v-card>
  </v-col>
</v-row>      
  </v-footer>
</template>

<script>
  export default {
    name: 'CoreFooter',

    components: {
      
    },
  }
</script>

<style scoped>
.v-btn::before {
  background-color: transparent;
}
</style>
